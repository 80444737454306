<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div class="fill-box">
                <div class="info-wrap">
                    <van-skeleton :row="3" style="padding: 10px;" :loading="loading">
                        <div class="info-item">
                            <span class="info-title">{{cinemaInfo.name}}</span>
                        </div>
                        <div class="info-item" @click="toLocation">
                            <van-icon name="location-o" color="#ff9900" size="16"></van-icon>
                            <span class="fc-tips">{{cinemaInfo.address}}</span>
                        </div>
                        <div class="info-item" @click.stop="call">
                            <van-icon name="phone-o" color="#FF4500" size="16"></van-icon>
                            <span class="fc-link">{{cinemaInfo.contactPhone}}</span>
                        </div>
                    </van-skeleton>
                </div>
                <div class="info-wrap">
                    <van-skeleton :row="4" style="padding: 10px;" :loading="loading">
                        <div class="info-item">
                            <span class="info-title">特色服务</span>
                        </div>
                        <div class="info-item" v-for="item,index of cinemaInfo.features" :key="index">
                            <van-tag plain :color="item.color">{{item.tag}}</van-tag>
                            <span class="fc-tips">{{item.desc}}</span>
                        </div>
                    </van-skeleton>
                </div>
                <div class="info-wrap">
                    <van-skeleton :row="5" style="padding: 10px;" :loading="loading">
                        <div class="info-item">
                            <span class="info-title">影院公告</span>
                        </div>
                        <div class="info-item">
                            <span class="fc-tips">{{cinemaInfo.notice}}</span>
                        </div>
                    </van-skeleton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCinemaInfo } from '@api/cinema-request'

export default {
    data() {
        return {
            cid: this.$route.query.cid,
            cinemaInfo: {},
            loading: false,
        }
    },
    methods: {
        getCinemaInfo() {
            this.loading = true;
            getCinemaInfo(this.cid).then((res) => {
                this.cinemaInfo = res;
                this.loading = false;
                this.$documentTitle(res.name)
            })
        },
        call() {
            location.href = `tel: ${this.cinemaInfo.contactPhone}`
        },
        toLocation() {
            this.$goPage('location-view', {
                name: this.cinemaInfo.name,
                address: this.cinemaInfo.address,
                lon: this.cinemaInfo.lon,
                lat: this.cinemaInfo.lat
            });
        }
    },
    created() {
        this.getCinemaInfo();
    }
}
</script>

<style lang='scss' scoped>
.info-wrap {
	background-color: #fff;
	margin-bottom: 10px;
	.info-item {
		padding: 8px 10px;
		line-height: 2;
		&:focus {
			background-color: $border-color;
		}
		&:not(:last-child) {
			border-bottom: 1px solid $border-color;
		}
		.info-title {
			color: #1b0909;
			font-weight: 700;
			font-size: 1.3em;
		}
		> * {
			&:not(:first-child) {
				margin-left: 4px;
			}
		}
	}
}
</style>
